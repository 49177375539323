import gsap from "gsap/gsap-core"
import * as React from "react"
import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import ArrowButton from "../components/ui/arrow-button"
import { scroll } from "../theme"

const NottFoundPage = () => {

  const marqueeRef = React.useRef()
  const [tl] = React.useState(gsap.timeline({ repeat: -1 }))

  React.useEffect(() => {
    gsap.to('body', {
      opacity: 1
    })
    gsap.to('.nav', {
      duration: 0.8,
      y: 0,
      ease: "power2.inOut"
    })

    const width = marqueeRef.current.getBoundingClientRect().width

    tl.to(marqueeRef.current, {
      x: `-50%`,
      ease: "none",
      duration: width / 50,
      force3D: true
    })
  }, [])

  return (
    <Layout
      scroll={scroll}>
      <SEO title="404 - Not Found" />
      <Nav />
      <section className="h-screen flex items-center justify-center text-sand w-screen">
        <div>
          <div ref={marqueeRef} className="marquee text-5xl">
            <div className="marquee-item">
              Page Not Found – Page Not Found – Page Not Found – Page Not Found –&nbsp;
          </div>
            <div className="marquee-item">
              Page Not Found – Page Not Found – Page Not Found – Page Not Found–&nbsp;
          </div>
          </div>
          <div className="flex mt-64">
            <div className="pr-4 text-sand">
              Sorry, we couldn’t find that page.
          </div>
            <ArrowButton to="/">
              <span className="text-canary">
                Go  Home
              </span>
            </ArrowButton>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NottFoundPage
